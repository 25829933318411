import { INaeProperty } from "@newageerp/v3.app.main-bundle";
export const NaeSProperties: INaeProperty[] = [
    {
        "schema": "front-order-log",
        "key": "createdAt",
        "type": "string",
        "format": "date-time",
        "title": "createdAt",
        "additionalProperties": [],
        "description": "",
        "className": "FrontOrderLog",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "front-order-log",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "FrontOrderLog",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "front-order-log",
        "key": "path",
        "type": "string",
        "format": "",
        "title": "path",
        "additionalProperties": [],
        "description": "",
        "className": "FrontOrderLog",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "front-order-log",
        "key": "referenceId",
        "type": "string",
        "format": "",
        "title": "referenceId",
        "additionalProperties": [],
        "description": "",
        "className": "FrontOrderLog",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "front-order-log",
        "key": "request",
        "type": "array",
        "format": "string",
        "title": "request",
        "additionalProperties": [],
        "description": "",
        "className": "FrontOrderLog",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "front-order-log",
        "key": "response",
        "type": "array",
        "format": "string",
        "title": "response",
        "additionalProperties": [],
        "description": "",
        "className": "FrontOrderLog",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "primer-dispute",
        "key": "amount",
        "type": "number",
        "format": "float",
        "title": "amount",
        "additionalProperties": [],
        "description": "",
        "className": "PrimerDispute",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "primer-dispute",
        "key": "currency",
        "type": "string",
        "format": "",
        "title": "currency",
        "additionalProperties": [],
        "description": "",
        "className": "PrimerDispute",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "primer-dispute",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "PrimerDispute",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "primer-dispute",
        "key": "orderId",
        "type": "string",
        "format": "",
        "title": "orderId",
        "additionalProperties": [],
        "description": "",
        "className": "PrimerDispute",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "primer-dispute",
        "key": "primerPayment",
        "type": "rel",
        "format": "primer-payment",
        "title": "primerPayment",
        "additionalProperties": [],
        "description": "",
        "className": "PrimerDispute",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "primer-dispute",
        "key": "primerTransaction",
        "type": "rel",
        "format": "primer-transaction",
        "title": "primerTransaction",
        "additionalProperties": [],
        "description": "",
        "className": "PrimerDispute",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "primer-dispute",
        "key": "processorDisputeId",
        "type": "string",
        "format": "",
        "title": "processorDisputeId",
        "additionalProperties": [],
        "description": "",
        "className": "PrimerDispute",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "primer-dispute",
        "key": "processorId",
        "type": "string",
        "format": "",
        "title": "processorId",
        "additionalProperties": [],
        "description": "",
        "className": "PrimerDispute",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "primer-dispute",
        "key": "processorReason",
        "type": "string",
        "format": "",
        "title": "processorReason",
        "additionalProperties": [],
        "description": "",
        "className": "PrimerDispute",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "primer-dispute",
        "key": "reason",
        "type": "string",
        "format": "",
        "title": "reason",
        "additionalProperties": [],
        "description": "",
        "className": "PrimerDispute",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "primer-dispute",
        "key": "reasonCode",
        "type": "string",
        "format": "",
        "title": "reasonCode",
        "additionalProperties": [],
        "description": "",
        "className": "PrimerDispute",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "primer-dispute",
        "key": "signedAt",
        "type": "string",
        "format": "date-time",
        "title": "signedAt",
        "additionalProperties": [],
        "description": "",
        "className": "PrimerDispute",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "primer-dispute",
        "key": "status",
        "type": "string",
        "format": "",
        "title": "status",
        "additionalProperties": [],
        "description": "",
        "className": "PrimerDispute",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "primer-payment",
        "key": "amount",
        "type": "number",
        "format": "float",
        "title": "amount",
        "additionalProperties": [],
        "description": "",
        "className": "PrimerPayment",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "primer-payment",
        "key": "cardholderName",
        "type": "string",
        "format": "",
        "title": "cardholderName",
        "additionalProperties": [],
        "description": "",
        "className": "PrimerPayment",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "primer-payment",
        "key": "currency",
        "type": "string",
        "format": "",
        "title": "currency",
        "additionalProperties": [],
        "description": "",
        "className": "PrimerPayment",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "primer-payment",
        "key": "customerEmail",
        "type": "string",
        "format": "",
        "title": "customerEmail",
        "additionalProperties": [],
        "description": "",
        "className": "PrimerPayment",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "primer-payment",
        "key": "date",
        "type": "string",
        "format": "date-time",
        "title": "date",
        "additionalProperties": [],
        "description": "",
        "className": "PrimerPayment",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "primer-payment",
        "key": "first6Digits",
        "type": "string",
        "format": "",
        "title": "first6Digits",
        "additionalProperties": [],
        "description": "",
        "className": "PrimerPayment",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "primer-payment",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "PrimerPayment",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "primer-payment",
        "key": "last4Digits",
        "type": "string",
        "format": "",
        "title": "last4Digits",
        "additionalProperties": [],
        "description": "",
        "className": "PrimerPayment",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "primer-payment",
        "key": "metaData",
        "type": "array",
        "format": "string",
        "title": "metaData",
        "additionalProperties": [],
        "description": "",
        "className": "PrimerPayment",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "primer-payment",
        "key": "needSync",
        "type": "boolean",
        "format": "",
        "title": "needSync",
        "additionalProperties": [],
        "description": "",
        "className": "PrimerPayment",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "primer-payment",
        "key": "network",
        "type": "string",
        "format": "",
        "title": "network",
        "additionalProperties": [],
        "description": "",
        "className": "PrimerPayment",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "primer-payment",
        "key": "orderId",
        "type": "string",
        "format": "",
        "title": "orderId",
        "additionalProperties": [],
        "description": "",
        "className": "PrimerPayment",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "primer-payment",
        "key": "processor",
        "type": "string",
        "format": "",
        "title": "processor",
        "additionalProperties": [],
        "description": "",
        "className": "PrimerPayment",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "primer-payment",
        "key": "sourceId",
        "type": "string",
        "format": "",
        "title": "sourceId",
        "additionalProperties": [],
        "description": "",
        "className": "PrimerPayment",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "primer-payment",
        "key": "status",
        "type": "string",
        "format": "",
        "title": "status",
        "additionalProperties": [],
        "description": "",
        "className": "PrimerPayment",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "primer-payment",
        "key": "subForceCancel",
        "type": "boolean",
        "format": "",
        "title": "subForceCancel",
        "additionalProperties": [],
        "description": "",
        "className": "PrimerPayment",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "primer-payment",
        "key": "transactions",
        "type": "array",
        "format": "primer-transaction",
        "title": "transactions",
        "additionalProperties": [
            {
                "mapped": "primerPayment"
            }
        ],
        "description": "",
        "className": "PrimerPayment",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "primer-transaction",
        "key": "amount",
        "type": "number",
        "format": "float",
        "title": "amount",
        "additionalProperties": [],
        "description": "",
        "className": "PrimerTransaction",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "primer-transaction",
        "key": "currency",
        "type": "string",
        "format": "",
        "title": "currency",
        "additionalProperties": [],
        "description": "",
        "className": "PrimerTransaction",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "primer-transaction",
        "key": "date",
        "type": "string",
        "format": "date-time",
        "title": "date",
        "additionalProperties": [],
        "description": "",
        "className": "PrimerTransaction",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "primer-transaction",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "PrimerTransaction",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "primer-transaction",
        "key": "primerPayment",
        "type": "rel",
        "format": "primer-payment",
        "title": "primerPayment",
        "additionalProperties": [],
        "description": "",
        "className": "PrimerTransaction",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "primer-transaction",
        "key": "processorName",
        "type": "string",
        "format": "",
        "title": "processorName",
        "additionalProperties": [],
        "description": "",
        "className": "PrimerTransaction",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "primer-transaction",
        "key": "processorStatus",
        "type": "string",
        "format": "",
        "title": "processorStatus",
        "additionalProperties": [],
        "description": "",
        "className": "PrimerTransaction",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "primer-transaction",
        "key": "sourceId",
        "type": "string",
        "format": "",
        "title": "sourceId",
        "additionalProperties": [],
        "description": "",
        "className": "PrimerTransaction",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "primer-transaction",
        "key": "transactionType",
        "type": "string",
        "format": "",
        "title": "transactionType",
        "additionalProperties": [],
        "description": "",
        "className": "PrimerTransaction",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "recurring",
        "key": "amount",
        "type": "number",
        "format": "float",
        "title": "amount",
        "additionalProperties": [],
        "description": "",
        "className": "Recurring",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "recurring",
        "key": "chargeId",
        "type": "string",
        "format": "",
        "title": "chargeId",
        "additionalProperties": [],
        "description": "",
        "className": "Recurring",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "recurring",
        "key": "createdAt",
        "type": "string",
        "format": "date-time",
        "title": "createdAt",
        "additionalProperties": [],
        "description": "",
        "className": "Recurring",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "recurring",
        "key": "currency",
        "type": "string",
        "format": "",
        "title": "currency",
        "additionalProperties": [],
        "description": "",
        "className": "Recurring",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "recurring",
        "key": "error",
        "type": "string",
        "format": "text",
        "title": "error",
        "additionalProperties": [],
        "description": "",
        "className": "Recurring",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "recurring",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Recurring",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "recurring",
        "key": "orderId",
        "type": "string",
        "format": "",
        "title": "orderId",
        "additionalProperties": [],
        "description": "",
        "className": "Recurring",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "recurring",
        "key": "piId",
        "type": "string",
        "format": "",
        "title": "piId",
        "additionalProperties": [],
        "description": "",
        "className": "Recurring",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "recurring",
        "key": "piStatus",
        "type": "string",
        "format": "",
        "title": "piStatus",
        "additionalProperties": [],
        "description": "",
        "className": "Recurring",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "recurring",
        "key": "sourceId",
        "type": "string",
        "format": "",
        "title": "sourceId",
        "additionalProperties": [],
        "description": "",
        "className": "Recurring",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "system-run",
        "key": "arguments",
        "type": "string",
        "format": "",
        "title": "arguments",
        "additionalProperties": [],
        "description": "",
        "className": "SystemRun",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "system-run",
        "key": "command",
        "type": "string",
        "format": "",
        "title": "command",
        "additionalProperties": [],
        "description": "",
        "className": "SystemRun",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "system-run",
        "key": "createdAt",
        "type": "string",
        "format": "date-time",
        "title": "createdAt",
        "additionalProperties": [],
        "description": "",
        "className": "SystemRun",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "system-run",
        "key": "executeFinish",
        "type": "string",
        "format": "date-time",
        "title": "executeFinish",
        "additionalProperties": [],
        "description": "",
        "className": "SystemRun",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "system-run",
        "key": "executeStart",
        "type": "string",
        "format": "date-time",
        "title": "executeStart",
        "additionalProperties": [],
        "description": "",
        "className": "SystemRun",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "system-run",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "SystemRun",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "system-run",
        "key": "response",
        "type": "string",
        "format": "text",
        "title": "response",
        "additionalProperties": [],
        "description": "",
        "className": "SystemRun",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "system-run",
        "key": "status",
        "type": "integer",
        "format": "",
        "title": "status",
        "additionalProperties": [],
        "description": "",
        "className": "SystemRun",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "webhook",
        "key": "createdAt",
        "type": "string",
        "format": "date-time",
        "title": "createdAt",
        "additionalProperties": [],
        "description": "",
        "className": "Webhook",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "webhook",
        "key": "data",
        "type": "array",
        "format": "string",
        "title": "data",
        "additionalProperties": [],
        "description": "",
        "className": "Webhook",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "webhook",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Webhook",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "webhook",
        "key": "processed",
        "type": "boolean",
        "format": "",
        "title": "processed",
        "additionalProperties": [],
        "description": "",
        "className": "Webhook",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "webhook",
        "key": "type",
        "type": "string",
        "format": "",
        "title": "Type",
        "additionalProperties": [],
        "description": "",
        "className": "Webhook",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    }
]