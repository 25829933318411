import { CustomEditComponentsMap } from "../_generated/_custom/edit/CustomEditComponentsMap";
import { CustomListComponentsMap } from "../_generated/_custom/tabs/CustomListComponentsMap";
import { CustomViewComponentsMap } from "../_generated/_custom/view/CustomViewComponentsMap";
import { CrmConfigSchema } from "./ConfigSchema/CrmConfigSchema";
import { PrimerConfigSchema } from "./ConfigSchema/PrimerConfigSchema";
import Dashboard from "./Dashboard/Dashboard";

export const PluginsMap: any = {
    ...CustomEditComponentsMap,
    ...CustomListComponentsMap,
    ...CustomViewComponentsMap,

    "custom.routes": {
        '/c/dashboard/main': Dashboard,
    },

    config: {
        primer: PrimerConfigSchema,
        crm: CrmConfigSchema,
    }
}