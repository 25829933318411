import { createSelector } from "redux-orm";
import orm from "./orm";

export const SelectorFrontOrderLogNae = createSelector(orm.FrontOrderLogModel);
export const SelectorPrimerDisputeNae = createSelector(orm.PrimerDisputeModel);
export const SelectorPrimerPaymentNae = createSelector(orm.PrimerPaymentModel);
export const SelectorPrimerTransactionNae = createSelector(orm.PrimerTransactionModel);
export const SelectorQueueNae = createSelector(orm.QueueModel);
export const SelectorRecurringNae = createSelector(orm.RecurringModel);
export const SelectorSystemRunNae = createSelector(orm.SystemRunModel);
export const SelectorWebhookNae = createSelector(orm.WebhookModel);

export const selectorBySchemaClassName = (schema) => {
        if (schema === 'FrontOrderLog') {
        return SelectorFrontOrderLogNae;
    }
        if (schema === 'PrimerDispute') {
        return SelectorPrimerDisputeNae;
    }
        if (schema === 'PrimerPayment') {
        return SelectorPrimerPaymentNae;
    }
        if (schema === 'PrimerTransaction') {
        return SelectorPrimerTransactionNae;
    }
        if (schema === 'Queue') {
        return SelectorQueueNae;
    }
        if (schema === 'Recurring') {
        return SelectorRecurringNae;
    }
        if (schema === 'SystemRun') {
        return SelectorSystemRunNae;
    }
        if (schema === 'Webhook') {
        return SelectorWebhookNae;
    }
    }

export const selectorBySchemaSlug = (schema) => {
        if (schema === 'front-order-log') {
        return SelectorFrontOrderLogNae;
    }
        if (schema === 'primer-dispute') {
        return SelectorPrimerDisputeNae;
    }
        if (schema === 'primer-payment') {
        return SelectorPrimerPaymentNae;
    }
        if (schema === 'primer-transaction') {
        return SelectorPrimerTransactionNae;
    }
        if (schema === '') {
        return SelectorQueueNae;
    }
        if (schema === 'recurring') {
        return SelectorRecurringNae;
    }
        if (schema === 'system-run') {
        return SelectorSystemRunNae;
    }
        if (schema === 'webhook') {
        return SelectorWebhookNae;
    }
    }