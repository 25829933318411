import { INaeSchema } from "@newageerp/v3.app.main-bundle"
export const NaeSSchema: INaeSchema[] = [
    {
        "className": "FrontOrderLog",
        "schema": "front-order-log",
        "title": "Order log",
        "titlePlural": "Order logs",
        "required": [],
        "scopes": []
    },
    {
        "className": "PrimerDispute",
        "schema": "primer-dispute",
        "title": "Dispute",
        "titlePlural": "Disputes",
        "required": [],
        "scopes": []
    },
    {
        "className": "PrimerPayment",
        "schema": "primer-payment",
        "title": "Payment",
        "titlePlural": "Payments",
        "required": [],
        "scopes": []
    },
    {
        "className": "PrimerTransaction",
        "schema": "primer-transaction",
        "title": "Transaction",
        "titlePlural": "Transactions",
        "required": [],
        "scopes": []
    },
    {
        "className": "Recurring",
        "schema": "recurring",
        "title": "Recurring",
        "titlePlural": "Recurrings",
        "required": [],
        "scopes": []
    },
    {
        "className": "SystemRun",
        "schema": "system-run",
        "title": "System run",
        "titlePlural": "System runs",
        "required": [],
        "scopes": []
    },
    {
        "className": "Webhook",
        "schema": "webhook",
        "title": "Webhook",
        "titlePlural": "Webhooks",
        "required": [],
        "scopes": []
    }
]
        export const NaeSSchemaMap = {
    "PrimerPayment": {
        "className": "PrimerPayment",
        "schema": "primer-payment"
    },
    "SystemRun": {
        "className": "SystemRun",
        "schema": "system-run"
    },
    "PrimerTransaction": {
        "className": "PrimerTransaction",
        "schema": "primer-transaction"
    },
    "PrimerDispute": {
        "className": "PrimerDispute",
        "schema": "primer-dispute"
    },
    "Webhook": {
        "className": "Webhook",
        "schema": "webhook"
    },
    "FrontOrderLog": {
        "className": "FrontOrderLog",
        "schema": "front-order-log"
    },
    "Recurring": {
        "className": "Recurring",
        "schema": "recurring"
    }
}