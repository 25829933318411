import { IFrontOrderLogFieldsNae } from './useFrontOrderLogHookNae';
import { IPrimerDisputeFieldsNae } from './usePrimerDisputeHookNae';
import { IPrimerPaymentFieldsNae } from './usePrimerPaymentHookNae';
import { IPrimerTransactionFieldsNae } from './usePrimerTransactionHookNae';
import { IRecurringFieldsNae } from './useRecurringHookNae';
import { ISystemRunFieldsNae } from './useSystemRunHookNae';
import { IWebhookFieldsNae } from './useWebhookHookNae';
import { SelectorFrontOrderLogNae,SelectorPrimerDisputeNae,SelectorPrimerPaymentNae,SelectorPrimerTransactionNae,SelectorRecurringNae,SelectorSystemRunNae,SelectorWebhookNae, } from "../models/ormSelectors"
import { NaeSSchemaMap } from '../config/NaeSSchema';
import { store } from '../models/ormstore';

export const getDataCacheForSchema = (className: string) => {
    if (className === NaeSSchemaMap.FrontOrderLog.className) {
            return {
                dataToCheck: SelectorFrontOrderLogNae(store.getState()),
                fields: IFrontOrderLogFieldsNae,    
            }
        }
    if (className === NaeSSchemaMap.PrimerDispute.className) {
            return {
                dataToCheck: SelectorPrimerDisputeNae(store.getState()),
                fields: IPrimerDisputeFieldsNae,    
            }
        }
    if (className === NaeSSchemaMap.PrimerPayment.className) {
            return {
                dataToCheck: SelectorPrimerPaymentNae(store.getState()),
                fields: IPrimerPaymentFieldsNae,    
            }
        }
    if (className === NaeSSchemaMap.PrimerTransaction.className) {
            return {
                dataToCheck: SelectorPrimerTransactionNae(store.getState()),
                fields: IPrimerTransactionFieldsNae,    
            }
        }
    if (className === NaeSSchemaMap.Recurring.className) {
            return {
                dataToCheck: SelectorRecurringNae(store.getState()),
                fields: IRecurringFieldsNae,    
            }
        }
    if (className === NaeSSchemaMap.SystemRun.className) {
            return {
                dataToCheck: SelectorSystemRunNae(store.getState()),
                fields: ISystemRunFieldsNae,    
            }
        }
    if (className === NaeSSchemaMap.Webhook.className) {
            return {
                dataToCheck: SelectorWebhookNae(store.getState()),
                fields: IWebhookFieldsNae,    
            }
        }
        return undefined;
}