import { useTemplatesLoader } from '@newageerp/v3.templates.templates-core';
import React, { Fragment } from 'react'
import ReactJson from 'react-json-view';

interface Props {
    fieldKey: string
}

export default function JsonField(props: Props) {
    const { data: tData } = useTemplatesLoader();

    const { element } = tData;

    if (!element) {
        return <Fragment />
    }

    return (
        <div>
            <ReactJson src={element[props.fieldKey]} name={false} />
        </div>
    )
}
