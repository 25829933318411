
import React, { Fragment } from 'react'
import { PropsId, PropsLink } from './types';
import moment from "moment";
import { SelectorFrontOrderLogNae, SelectorPrimerDisputeNae, SelectorPrimerPaymentNae, SelectorPrimerTransactionNae, SelectorRecurringNae, SelectorSystemRunNae, SelectorWebhookNae } from '../models/ormSelectors';
import { useFrontOrderLogHookNae } from '../hooks/useFrontOrderLogHookNae';
import { usePrimerDisputeHookNae } from '../hooks/usePrimerDisputeHookNae';
import { usePrimerPaymentHookNae } from '../hooks/usePrimerPaymentHookNae';
import { usePrimerTransactionHookNae } from '../hooks/usePrimerTransactionHookNae';
import { useRecurringHookNae } from '../hooks/useRecurringHookNae';
import { useSystemRunHookNae } from '../hooks/useSystemRunHookNae';
import { useWebhookHookNae } from '../hooks/useWebhookHookNae';


export const useEmptyHook = (id: number) => {
            return undefined;
        }

export const getHookForSchema = (schema: string) => {
  let selector : any = useEmptyHook;
  if (schema === 'front-order-log') {
    return useFrontOrderLogHookNae;
  }
  if (schema === 'primer-dispute') {
    return usePrimerDisputeHookNae;
  }
  if (schema === 'primer-payment') {
    return usePrimerPaymentHookNae;
  }
  if (schema === 'primer-transaction') {
    return usePrimerTransactionHookNae;
  }
  if (schema === 'recurring') {
    return useRecurringHookNae;
  }
  if (schema === 'system-run') {
    return useSystemRunHookNae;
  }
  if (schema === 'webhook') {
    return useWebhookHookNae;
  }
  return selector;
}

export function FrontOrderLogEmptyFieldNae(props: PropsLink) {
  const element = useFrontOrderLogHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

export function PrimerDisputeEmptyFieldNae(props: PropsLink) {
  const element = usePrimerDisputeHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

export function PrimerPaymentEmptyFieldNae(props: PropsLink) {
  const element = usePrimerPaymentHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

export function PrimerTransactionEmptyFieldNae(props: PropsLink) {
  const element = usePrimerTransactionHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

export function RecurringEmptyFieldNae(props: PropsLink) {
  const element = useRecurringHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

export function SystemRunEmptyFieldNae(props: PropsLink) {
  const element = useSystemRunHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

export function WebhookEmptyFieldNae(props: PropsLink) {
  const element = useWebhookHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

