import { ORM } from "redux-orm";
import FrontOrderLogModel from "./FrontOrderLogModel"
import PrimerDisputeModel from "./PrimerDisputeModel"
import PrimerPaymentModel from "./PrimerPaymentModel"
import PrimerTransactionModel from "./PrimerTransactionModel"
import QueueModel from "./QueueModel"
import RecurringModel from "./RecurringModel"
import SystemRunModel from "./SystemRunModel"
import WebhookModel from "./WebhookModel"

export const orm = new ORM({
    stateSelector: (state) => {
        return state.orm; // wherever the reducer is put during createStore
    },
});
orm.register(
    FrontOrderLogModel,
    PrimerDisputeModel,
    PrimerPaymentModel,
    PrimerTransactionModel,
    QueueModel,
    RecurringModel,
    SystemRunModel,
    WebhookModel,
);

export default orm;